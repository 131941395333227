exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-artwork-erratic-index-js": () => import("./../../../src/pages/artwork/erratic/index.js" /* webpackChunkName: "component---src-pages-artwork-erratic-index-js" */),
  "component---src-pages-artwork-flux-index-js": () => import("./../../../src/pages/artwork/flux/index.js" /* webpackChunkName: "component---src-pages-artwork-flux-index-js" */),
  "component---src-pages-artwork-mva-studies-index-js": () => import("./../../../src/pages/artwork/mva-studies/index.js" /* webpackChunkName: "component---src-pages-artwork-mva-studies-index-js" */),
  "component---src-pages-artwork-photosynthetic-index-js": () => import("./../../../src/pages/artwork/photosynthetic/index.js" /* webpackChunkName: "component---src-pages-artwork-photosynthetic-index-js" */),
  "component---src-pages-artwork-quarantine-index-js": () => import("./../../../src/pages/artwork/quarantine/index.js" /* webpackChunkName: "component---src-pages-artwork-quarantine-index-js" */),
  "component---src-pages-artwork-spaghettification-index-js": () => import("./../../../src/pages/artwork/spaghettification/index.js" /* webpackChunkName: "component---src-pages-artwork-spaghettification-index-js" */),
  "component---src-pages-artwork-waveshapes-index-js": () => import("./../../../src/pages/artwork/waveshapes/index.js" /* webpackChunkName: "component---src-pages-artwork-waveshapes-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

